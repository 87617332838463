import { COUNTRY } from '../common/country'

if (process.env.NODE_ENV === 'production') {
  window.dataLayer = window.dataLayer || []

  function gtag() {
    window.dataLayer.push(arguments)
  }

  const g_code = COUNTRY === 'kg' ? 'G-RRP9V53GST' : 'G-7XHLF1QXND'

  gtag('js', new Date())
  gtag('config', g_code)

  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${g_code}`

  let _script = document.getElementsByTagName('script')[0]
  _script.parentNode.insertBefore(script, _script)
}

import autobind from 'autobind-decorator'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AutoButton, AutoForm, FormCheckbox, FormInput } from '../ui/FormInput'
import Frame3D from './Frame3D'
import PaymentCancel from './PaymentCancel'
import { Translation, i18n } from '../../../common'

@inject('store')
@observer
export default class Elsom extends Component {
  render() {
    const { payStore } = this.props.store
    let { elsom } = payStore
    return elsom.url ? (
      <div>
        <Row className='mv-4'>
          <Col xs={12} sm={3} md={4} />
          <Col xs={12} sm={6} md={4}>
            <AutoButton block variant='warning' onClick={payStore.elsomRestart}>
              <Translation ns='payment'>{t => t('elsom.cancel')}</Translation>
            </AutoButton>
          </Col>
        </Row>
        <Frame3D {...elsom} />
        <PaymentCancel payment_id={elsom.payment_id} />
      </div>
    ) : (
      <div>
        <Row className='margin-bottom'>
          <Col xs={12} sm={3} />
          <Col xs={12} sm={6}>
            <div className='elsom_logo' />
          </Col>
        </Row>
        <AutoForm onSubmit={this.pay}>
          <Row>
            <Col xs={12} sm={3} />
            <Col xs={12} sm={6}>
              <FormGroup className='text-center'>
                <FormInput
                  inputName='number'
                  model={elsom}
                  name='phone'
                  placeholder={i18n.t('elsom.phone', { ns: 'payment' })}
                  required
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  <Translation ns='payment'>
                    {t => t('elsom.enter-email')}
                  </Translation>
                </FormLabel>
                <FormInput
                  inputName='email'
                  type='email'
                  model={payStore}
                  name='email'
                  placeholder={i18n.t('elsom.email', { ns: 'payment' })}
                  required
                />
                <ul className='elsom_info'>
                  <li>
                    <Translation ns='payment'>
                      {t => t('elsom.info')}
                    </Translation>
                  </li>
                </ul>
              </FormGroup>
              <FormGroup>
                <FormCheckbox name='acceptElsom' model={payStore} required>
                  <Translation ns='payment'>{t => t('confirm')}</Translation>{' '}
                  <Link
                    target='_blank'
                    to='/pay-agreement'
                    className='conditions'
                  >
                    <Translation ns='payment'>{t => t('terms')}</Translation>
                  </Link>{' '}
                  <Translation ns='payment'>{t => t('and')}</Translation>
                  <Link target='_blank' to='/ad-info' className='conditions'>
                    {' '}
                    <Translation ns='payment'>{t => t('ad-info')}</Translation>
                  </Link>
                </FormCheckbox>
              </FormGroup>
              <div className='padding'>
                <FormGroup className='text-center'>
                  <AutoButton type='submit' disabled={!payStore.canPayElsom}>
                    <Translation ns='payment'>
                      {t => t('start-pay')}
                    </Translation>
                  </AutoButton>
                </FormGroup>
              </div>
            </Col>
          </Row>
        </AutoForm>
      </div>
    )
  }

  @autobind
  pay() {
    let { appState, payStore } = this.props.store
    const { card } = appState
    payStore
      .payByElsom(card ? card.code : undefined)
      .catch(this.props.onPayFailed)
  }
}

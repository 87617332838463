import parsePhoneNumberFromString from 'libphonenumber-js'
import { action, computed, observable } from 'mobx'
import { OS } from '../common/platform'
import requester from '../common/requester'
import BaseStore from './BaseStore'

export const SEAT_UNKNOWN = 0
export const SEAT_RESERVED = 1
export const SEAT_SOLD = 2
export const SEAT_FREE = 3
export const SEAT_CANCELED = 4
export const SEAT_SELECTED = 5
export const SEAT_DISABLED = 6
export const SEAT_FREE_RESERVED = 7
export const SEAT_LOCKED = 8
export const SEAT_RESERVED_VIP = 9
export const SEAT_USER_RESERVED = 100

export const SEATS_RESERVE_MAX = 6

export default class PayStore extends BaseStore {
  @observable type = ''
  @observable email = ''
  @observable phone = ''
  @observable card = { params: null, payment_id: null, url: null }
  @observable mbank = { phone: '', otp: '', payment_id: null }
  @observable balance = { phone: '', otp: '', payment_id: null }
  @observable megapay = { phone: '', otp: '', payment_id: null }
  @observable elsom = { payment_id: null, phone: '', url: null }
  @observable payze = { params: null, payment_id: null, url: null }
  @observable dis = { params: null, payment_id: null, url: null }
  @observable payment = { status: null, ticket: null }

  @observable seats = observable.array([])
  @observable repertory
  @observable movie
  @observable hall
  @observable cinema

  @observable repertoryInfo = null
  @observable scheme = null
  @observable vacant_seats = observable.array([])
  @observable reserved_seats = observable.array([])
  @observable prices = observable.array([])
  @observable accounts = observable.array([])
  @observable hover_seat = null

  @observable acceptCard = false
  @observable acceptMBank = false
  @observable acceptBalance = false
  @observable acceptElsom = false
  @observable acceptMegaPay = false

  @computed get onlineSale() {
    return (
      this.repertoryInfo &&
      this.repertoryInfo.online_sale !== false &&
      this.repertoryInfo.disable_sales !== true
    )
  }

  @computed get payAmount() {
    const account = this.accounts.find(
      a => a.type === '1' && a.name === 'ДИСКОНТНЫЙ' && a.blocked === '0',
    )
    const amount = this.seats
      .map(s => {
        if (!this.prices) return 0
        const { price, discounts } = this.prices.find(p => p.type === s.type)
        const discount = discounts.find(d =>
          s.d ? d.code === s.d : account && d.code === account.code,
        )
        return discount ? discount.price : price
      })
      .reduce((sum, p) => sum + p, 0)
    return parseFloat(amount.toFixed(2))
  }

  @computed get pay_amount() {
    return this.payAmount
  }

  @computed get valid_email() {
    return this.email.trim().length > 0
  }

  @computed get valid_phone() {
    if (this.phone.trim().length === 0) return undefined
    try {
      const phone = parsePhoneNumberFromString(this.phone.trim(), 'UZ')
      if (phone && phone.isValid() && phone.isPossible()) return phone.number
    } catch {}
  }

  @computed
  get canPayCard() {
    return this.valid_email && !!this.acceptCard
  }

  @computed
  get canPayMBank() {
    const { acceptMBank, mbank } = this
    return this.valid_email && !!acceptMBank && mbank.phone.trim().length > 0
  }

  @computed
  get canPayBalance() {
    const { acceptBalance, balance } = this
    return (
      this.valid_email && !!acceptBalance && balance.phone.trim().length > 0
    )
  }

  @computed
  get canPayMegaPay() {
    const { acceptMegaPay, megapay } = this
    return (
      this.valid_email && !!acceptMegaPay && megapay.phone.trim().length > 0
    )
  }

  @computed
  get canPayElsom() {
    const { acceptElsom, elsom } = this
    return this.valid_email && !!acceptElsom && elsom.phone.trim().length > 0
  }

  @computed
  get canPayPayze() {
    return this.valid_email && !!this.valid_phone && !!this.acceptCard
  }

  @computed
  get canPayDis() {
    return this.valid_email && !!this.valid_phone && !!this.acceptCard
  }

  getPayload(discount_card_code) {
    const account =
      this.accounts.filter(
        a => a.type === '1' && a.name === 'ДИСКОНТНЫЙ' && a.blocked === '0',
      )[0] || {}
    return {
      email: this.email,
      phone: this.valid_phone,
      seats: this.seats.map(s => {
        const { discounts } = this.prices.filter(p => p.type === s.type)[0]
        const discount = discounts.filter(d => {
          return s.d ? d.code === s.d : d.code === account.code
        })[0]
        const d = discount && discount.code ? discount.code : ''
        return { row: s.row, number: s.number, type: s.type, d: d }
      }),
      repertory_id: this.repertory,
      hall_id: this.hall,
      cinema_id: this.cinema,
      discount_card_code,
      platform: OS,
    }
  }

  async payByCard(discount_card_code) {
    if (!this.canPayCard) return
    const {
      data: { params, payment_id, url },
    } = await requester.post(
      '/payment/card',
      this.getPayload(discount_card_code),
      2,
    )
    this.setCard({ params, payment_id, url })
    const resp = await requester.payment(payment_id)
    this.cardPayConfirm(resp.data)
  }

  @action setCard = value => (this.card = { ...this.card, ...value })

  @action cardPayConfirm(data) {
    requester.cancelPayment = true
    this.setCard({ payment_id: null, url: null })
    this.payment = data
  }

  async payByPayze(discount_card_code) {
    if (!this.canPayPayze) return
    const {
      data: { payment_id, url },
    } = await requester.post(
      '/payment/payze',
      this.getPayload(discount_card_code),
    )
    this.setPayze({ payment_id, url })
    const resp = await requester.payment(payment_id)
    this.payzePayConfirm(resp.data)
  }

  @action setPayze = value => (this.payze = { ...this.payze, ...value })

  @action payzePayConfirm(data) {
    requester.cancelPayment = true
    this.setPayze({ payment_id: null, url: null })
    this.payment = data
  }

  async payByDis(discount_card_code) {
    if (!this.canPayDis) return
    const {
      data: { payment_id, url, params },
    } = await requester.post(
      '/payment/dis',
      this.getPayload(discount_card_code),
    )
    this.setDis({ payment_id, url, params })
    const resp = await requester.payment(payment_id)
    this.disPayConfirm(resp.data)
  }

  @action setDis = value => (this.dis = { ...this.dis, ...value })

  @action disPayConfirm(data) {
    requester.cancelPayment = true
    this.setDis({ payment_id: null, url: null, params: null })
    this.payment = data
  }

  payBy = async (service, discount_card_code) => {
    let payload = this.getPayload(discount_card_code)
    payload.phone = this[service].phone || this.valid_phone
    let { data } = await requester.post(`/payment/${service}`, payload)
    this.setPaymentId(service, data.payment_id)
    const resp = await requester.payment(data.payment_id)
    requester.cancelPayment = true
    this.setPayment(service, resp.data)
  }

  @action setPaymentId = (service, payment_id) =>
    (this[service] = { ...this[service], payment_id })

  finishPayment = async service => {
    let { data } = await requester.post(`/payment/${service}.ok`, this[service])
    if (service !== 'mbank') this.setPayment(service, data)
  }

  @action setPayment = (service, data) => {
    this.setPaymentId(service, null)
    this.payment = data
  }

  cancelPayment = async service => {
    const { payment_id } = this[service]
    payment_id &&
      (await requester.post(`/payment/${service}.cancel`, { payment_id }))
    this.restartPayment()
  }

  payByMBank = async discount_card_code =>
    this.canPayMBank && (await this.payBy('mbank', discount_card_code))
  mbankFinish = () => this.finishPayment('mbank')
  mbankRestart = () => this.cancelPayment('mbank')

  payByBalance = async discount_card_code =>
    this.canPayBalance && (await this.payBy('balance', discount_card_code))
  balanceFinish = () => this.finishPayment('balance')
  balanceRestart = () => this.cancelPayment('balance')

  payByMegaPay = async discount_card_code =>
    this.canPayMegaPay && (await this.payBy('megapay', discount_card_code))
  megapayFinish = () => this.finishPayment('megapay')
  megapayRestart = () => this.cancelPayment('megapay')

  @action restartPayment = () => {
    requester.cancelPayment = true

    this.payment = { status: null, ticket: null }

    this.card = { ...this.card, payment_id: null, url: null }
    this.mbank = { ...this.mbank, otp: '', payment_id: null }
    this.balance = { ...this.balance, otp: '', payment_id: null }
    this.megapay = { ...this.megapay, otp: '', payment_id: null }
    this.elsom = { ...this.elsom, payment_id: null, url: null }
  }

  async payByElsom(discount_card_code) {
    if (!this.canPayElsom) return
    const payload = {
      ...this.getPayload(discount_card_code),
      phone: this.elsom.phone,
    }
    const {
      data: { payment_id, url },
    } = await requester.post('/payment/elsom', payload, 2)
    this.setElsom({ payment_id, url })
    const resp = await requester.payment(payment_id)
    this.elsomPayConfirm(resp.data)
  }

  @action setElsom = value => (this.elsom = { ...this.elsom, ...value })

  @action elsomPayConfirm(data) {
    requester.cancelPayment = true
    this.setElsom({ payment_id: null, url: null })
    this.payment = data
  }

  elsomRestart = () => this.cancelPayment('elsom')

  @action reserve = info => {
    this.repertory !== info.id && this.seats.clear()
    this.repertory = info.id
    this.movie = info.movie_id
    this.hall = info.hall_id
    this.cinema = info.cinema_id
    return this.getSeats()
  }

  getSeats = async () => {
    const { data } = await requester.get(
      `/repertory/seats/${this.cinema}/${this.hall}/${this.movie}/${this.repertory}`,
    )
    this.setData(data)
    return data
  }

  @action setData(data) {
    this.repertoryInfo = data.repertory
    this.vacant_seats.replace(data.vacant_seats || [])
    this.reserved_seats.replace(data.reserved_seats || [])
    let seats = [...this.seats]
    if (data.scheme) {
      for (let row of data.scheme.rows) {
        for (let seat of row.seats) {
          seat.status = this.seatStatus(seat)
          if (seat.status !== SEAT_FREE && this.seats.length > 0) {
            seats = seats.filter(
              s => seat.row !== s.row || seat.number !== s.number,
            )
          }
        }
      }
    } else {
      seats = []
    }
    this.scheme = data.scheme
    this.prices.replace(data.prices || [])
    this.accounts.replace(data.accounts || [])
    this.seats.replace(seats)
  }

  @action
  reserveCancel(saveSeats = false) {
    this.hover_seat = null
    this.repertoryInfo = null
    this.restartPayment()
    this.setData({})
    if (!saveSeats) this.seats.clear()
  }

  @action
  markAsUserReserved() {
    if (this.scheme)
      for (let row of this.scheme.rows)
        for (let seat of row.seats)
          if (
            seat.status === SEAT_FREE &&
            this.seats.filter(
              s => seat.row === s.row && seat.number === s.number,
            ).length === 1
          )
            seat.status = SEAT_USER_RESERVED
    this.seats.clear()
  }

  @action
  setEmail(email) {
    this.email = email || ''
  }

  @action
  setPhone(phone) {
    this.phone = phone || ''
  }

  @action
  selectSeat(row, number, type) {
    let index = -1
    for (let i = 0; i < this.seats.length; i++) {
      let seat = this.seats[i]
      if (seat.row === row && seat.number === number) {
        index = i
        break
      }
    }
    let deleted = []
    if (index === -1) {
      if (this.seats.length === SEATS_RESERVE_MAX) return
      this.seats.push({
        row: row,
        number: number,
        type: type,
        d: '',
      })
    } else deleted = this.seats.splice(index, 1)

    let numbers = []
    for (let i = 0; i < this.seats.length; i++)
      if (this.seats[i].row === row) numbers.push(this.seats[i].number * 1)
    numbers = numbers.sort((a, b) => a - b)
    let lastNumber = null
    for (let n of numbers) {
      if (lastNumber && Math.abs(lastNumber - n) > 1) {
        if (index === -1) this.seats.pop()
        else if (deleted.length > 0) this.seats.splice(index, 0, deleted[0])
      }
      lastNumber = n
    }
  }

  @action
  removeSeat(index) {
    this.selectSeat(this.seats[index].row, this.seats[index].number)
  }

  @action.bound
  hoverSeat(row, number) {
    this.hover_seat = row && number ? [row, number] : null
  }

  @action
  setSeatDiscount(index, type) {
    this.seats[index] = { ...this.seats[index], d: type }
  }

  seatStatus(seat) {
    return this.vacant_seats.filter(vacant_seat => vacant_seat.id === seat.id)
      .length === 1
      ? SEAT_FREE
      : this.reserved_seats.filter(
          r_seat => r_seat.row === seat.row && r_seat.number === seat.number,
        ).length === 1
      ? SEAT_USER_RESERVED
      : SEAT_SOLD
  }
}

import autobind from 'autobind-decorator'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Col, FormGroup, Row, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { i18n, Translation } from '../../../common'
import { AutoButton, AutoForm, FormCheckbox, FormInput } from '../ui/FormInput'
import PaymentCancel from './PaymentCancel'

@inject('store')
@observer
export default class MBank extends Component {
  state = { pending: false }

  render() {
    let { payStore } = this.props.store
    return (
      <div>
        <Row>
          <Col xs={12} sm={3} />
          <Col xs={12} sm={6}>
            <div className='mbank_logo' />
          </Col>
        </Row>
        <Row>
          {this.state.pending ? (
            <Col xs={12} className='text-center p-4'>
              Платеж в обработке, ожидайте...
            </Col>
          ) : !payStore.mbank.payment_id ? (
            <AutoForm style={{ marginTop: 15 }} onSubmit={this.pay}>
              <Stack direction='vertical' gap={2}>
                <Row>
                  <Col xs={12} sm={3} />
                  <Col xs={12} sm={6}>
                    <FormInput
                      placeholder={i18n.t('mbank.phone', { ns: 'payment' })}
                      name='phone'
                      inputName='number'
                      model={payStore.mbank}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={3} />
                  <Col xs={12} sm={6}>
                    <FormInput
                      placeholder={i18n.t('mbank.email', { ns: 'payment' })}
                      type='email'
                      name='email'
                      inputName='email'
                      model={payStore}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={3} />
                  <Col xs={12} sm={6} className='text-center'>
                    <FormCheckbox name='acceptMBank' model={payStore} required>
                      <Translation ns='payment'>
                        {t => t('confirm')}
                      </Translation>{' '}
                      <Link
                        target='_blank'
                        to='/pay-agreement'
                        className='conditions'
                      >
                        <Translation ns='payment'>
                          {t => t('terms')}
                        </Translation>
                      </Link>
                      <Translation ns='payment'>{t => t('and')}</Translation>
                      <Link
                        target='_blank'
                        to='/ad-info'
                        className='conditions'
                      >
                        <Translation ns='payment'>
                          {t => t('ad-info')}
                        </Translation>
                      </Link>
                    </FormCheckbox>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col xs='auto'>
                    <AutoButton type='submit' disabled={!payStore.canPayMBank}>
                      <Translation ns='payment'>
                        {t => t('start-pay')}
                      </Translation>
                    </AutoButton>
                  </Col>
                </Row>
              </Stack>
            </AutoForm>
          ) : (
            <AutoForm>
              <Stack direction='vertical' gap={2}>
                <Row>
                  <Col xs={12} sm={3} />
                  <Col xs={12} sm={6}>
                    <div className='padding'>
                      <FormGroup className='text-center'>
                        <FormInput
                          placeholder={i18n.t('mbank.otp', { ns: 'payment' })}
                          name='otp'
                          inputName='otp'
                          model={payStore.mbank}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col xs='auto'>
                    <Stack direction='horizontal' gap={2}>
                      <AutoButton
                        variant='warning'
                        onClick={payStore.mbankRestart}
                      >
                        <Translation ns='payment'>{t => t('anew')}</Translation>
                      </AutoButton>
                      <AutoButton
                        disabled={!payStore.mbank.otp}
                        onClick={this.finish}
                      >
                        <Translation ns='payment'>{t => t('pay')}</Translation>
                      </AutoButton>
                    </Stack>
                  </Col>
                </Row>
              </Stack>
              <PaymentCancel payment_id={payStore.mbank.payment_id} />
            </AutoForm>
          )}
        </Row>
      </div>
    )
  }

  @autobind
  pay() {
    let { appState, payStore } = this.props.store
    const { card } = appState
    payStore
      .payByMBank(card ? card.code : undefined)
      .catch(this.props.onPayFailed)
  }

  @autobind
  finish() {
    let { payStore } = this.props.store
    payStore.mbankFinish().then(this.setStatePending)
  }

  @autobind
  setStatePending() {
    this.setState({ pending: true })
  }
}
